import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import Heading from '../components/Heading'
import Layout from '../components/Layout'
import Article from '../components/Article'

const Contact = ({ data }) => {
  const postNode = {
    title: `Contact | ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Contact | ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <Article>
        <Heading.H1>Contact</Heading.H1>
        <ContactForm />
      </Article>
    </Layout>
  )
}

export default Contact
